@import "../../scss/variables";
@import "../../scss/fonts";

.not-found {
  width: 75rem;
  margin: 6.2rem 0 6.2rem 0;
  text-align: center;
  .nf-headline {
    font-family: TCCC-UnityHeadline-Bold;
    font-size: 4.8rem;
    line-height: 5.8rem;
    letter-spacing: 0;
    color: $color-black;
  }
  .nf-description {
    font-family: TCCC-UnityHeadline-Bold;
    font-size: 2.5rem;
    line-height: 2.8rem;
    margin-top: 1.4rem;
    color: $color-black;
  }
}

@media (max-width: $breakpoint_upto_page) {
  .not-found {
    width: 100%;
    margin: 6.2rem 0.5rem 6.2rem 0.5rem;
    .nf-headline {
      font-size: 3.8rem;
      line-height: 5rem;
    }
    .nf-description {
      font-size: 2rem;
      line-height: 2.2rem;
    }
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .not-found {
    .nf-headline {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    .nf-description {
      font-size: 1.5rem;
      line-height: 2.2rem;
    }
  }
}