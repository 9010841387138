@import "../../../scss/fonts";
@import "../../../scss/variables";

.alert-message {
  margin-top: 1rem !important;
  font-size: 1.4rem;
  font-family: TCCC-UnityText-Bold;
  line-height: 2.6rem;
  .alert-link {
    border: none;
    background-color: inherit;
    font-weight: bold;
    text-decoration: none;
    font-size: 110%;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: $breakpoint_tablet_max) {
  .alert-message {
    margin: 0 0.5rem;
  }
}
