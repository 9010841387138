@import "../../scss/_variables.scss";
@import "../../scss/_fonts.scss";

.up-page {
  width: $pageSize;
  justify-content: center;
  margin: 0 auto;
  max-width: $pageSize;
  .up-form {
    margin-top: 6.2rem;
    width: 45rem;
  }
  .up-button {
    input {
      font-family: TCCC-UnityHeadline-Bold;
      font-size: 1.4rem;
      width: 17rem;
      border-radius: 2rem;
      background-color: $color-black;
      border: solid 0.1rem $color-black;
      color: $color-white;
      margin: 4rem 0 6.4rem 0;
      height: 3.4rem;
      line-height: 0rem;
      cursor: pointer;
      &:hover {
        color: $color-white;
      }
    }
  }
}

.login-register-button {
  a {
    width: 17rem;
    border-radius: 2rem;
    background-color: $color-dark-gray1;
    border: solid 0.1rem $color-dark-gray1;
    color: $color-black;
    margin: 5.3rem 0 6.2rem 0;
    height: 3.4rem;
  }
}

@media (max-width: $breakpoint_upto_page) {
  .up-page {
    width: auto;
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .up-page {
    padding: 0 1rem;
    .up-form {
      width: auto;
    }
  }
}
