@import "../../../scss/variables";
@import "../../../scss/fonts";

footer {
  .footer-top {
    font-family: TCCC-UnityText-Regular;
    font-size: 1.4rem;
    line-height: 1.6rem;
    height: 13.5rem;
    background-color: $color-light-gray;
    text-align: center;
    .ft-container {
      width: $pageSize;
      margin: 0 auto;
      p {
        padding-top: 3.8rem;
        font-size: 1.4rem;
        line-height: 1.6rem;

        .paragraph {
          font-family: TCCC-UnityText-Regular;
          font-size: 1.4rem;
          line-height: 1.6rem;
        }
        .paragraph2 {
          font-family: TCCC-UnityText-Bold;
          font-size: 1.4rem;
          line-height: 1.6rem;
        }
      }
      a {
        text-decoration: underline;
        color: $color-black;
        font-weight: bold;
      }
    }
    .footer-menu {
      height: 2.8rem;
      margin-top: 1rem;
      .collapse:not(.show) {
        display: block !important;
      }
      .navbar-nav {
        display: inline-block;
        .nav-item {
          font-family: TCCC-UnityText-Bold;
          padding: 0 2rem 0 2rem;
          font-size: 1.4rem;
          line-height: 1.6rem;
          display: inline-block;
          &:hover {
            cursor: pointer;
          }
          .nav-link {
            background-color: $color-light-gray;
            border: none;
            text-decoration: underline;
            color: black;
          }
        }
      }
    }
  }
  .footer-bottom {
    font-family: TCCC-UnityText-Medium;
    font-size: 1.2rem !important;
    height: 7.2rem;
    line-height: 7.2rem;
    background-color: $color-black;
    color: $color-white;
    text-align: right;
    .fb-container {
      width: $pageSize;
      margin: 0 auto;
      p {
        line-height: 7.2rem;
        font-family: TCCC-UnityText-Regular;
        font-size: 1.1rem;
        letter-spacing: 0.08rem;
      }
    }
  }
}

@media (max-width: $breakpoint_upto_page) {
  footer {
    width: 100%;
    height: auto;
    .footer-top {
      .ft-container {
        width: 100%;
        p {
          padding: 3.8rem 0.5rem 0 0.5rem;
        }
      }
    }
    .footer-bottom {
      height: 7.2rem;
      .fb-container {
        width: 100%;
        margin-top: 0.5rem;
        p {
          line-height: initial;
          padding: 2rem 0.5rem 0 0.5rem;
          height: auto;
          font-size: 0.8rem;
        }
      }
    }
  }
}

@media (max-width: $breakpoint_mobile_max) {
  footer {
    .footer-top {
      height: auto;
      padding: 1rem 1rem 0 1rem;
      .ft-container {
        p {
          padding: 0;
          line-height: 2.4rem;
        }
        .paragraph2 {
          word-break: break-all;
        }
      }
      .footer-menu {
        height: auto !important;
      }
    }
    .footer-bottom {
      .fb-container {
        margin: 0;
        text-align: center;
        p {
          word-break: break-all;
          line-height: 2.4rem;
        }
      }
    }
  }
}
