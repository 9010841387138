//
// ██╗░░░██╗░█████╗░██████╗░██╗░█████╗░██████╗░██╗░░░░░███████╗░██████╗
// ██║░░░██║██╔══██╗██╔══██╗██║██╔══██╗██╔══██╗██║░░░░░██╔════╝██╔════╝
// ╚██╗░██╔╝███████║██████╔╝██║███████║██████╦╝██║░░░░░█████╗░░╚█████╗░
// ░╚████╔╝░██╔══██║██╔══██╗██║██╔══██║██╔══██╗██║░░░░░██╔══╝░░░╚═══██╗
// ░░╚██╔╝░░██║░░██║██║░░██║██║██║░░██║██████╦╝███████╗███████╗██████╔╝
// ░░░╚═╝░░░╚═╝░░╚═╝╚═╝░░╚═╝╚═╝╚═╝░░╚═╝╚═════╝░╚══════╝╚══════╝╚═════╝░
//

$color-red: #f40000;
$color-white: #fff;
$color-black: #000;
$color-light-gray: #f0f0f0;
$color-medium-gray: #dadbdc;
$color-dark-gray: #c2c2c2;
$color-dark-gray1: #b3b3b3;

$base_font_size: 10px;

$layoutSize: 1280px !default;
$pageSize: 1000px;
$pageInnerSize: 750px;

$app_base_font: TCCC-UnityText-Light !default;
$app_base_font_bold: TCCC-UnityText-Bold !default;

// Responsive breakpoints
$breakpoint_mobile_min: 320px;
$breakpoint_mobile_max: 480px;

$breakpoint_tablet_min: 768px;
$breakpoint_tablet_max: 1024px;

$breakpoint_desktop_min: 1025px;
$breakpoint_desktop_max: 1367px;

$breakpoint_desktop_lg_min: 1536px;

$breakpoint_upto_tablet: $breakpoint_tablet_min - 1;
$breakpoint_upto_desktop: $breakpoint_desktop_max - 1;
$breakpoint_upto_page: $pageSize - 1;
