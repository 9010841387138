@import "../../../scss/fonts";
@import "../../../scss/variables";

.cookie-notice {
  width: 65rem;
  font-size: 1.6rem;
  color: $color-black;
  background-color: $color-white;
  font-family: 'TCCC-UnityText-Bold';
  line-height: 2.8rem;
  text-align: center;
  letter-spacing: 0.1rem;
  .description {
    padding: 1.2rem;
  }
  .navbar-nav {
    color: $color-black;
    margin: 0 auto;
    height: 4.5rem;
  }
  .nav-item {
    border-right: 0.2rem solid $color-black;
    padding: 0 1.5rem 0 1.5rem;
    height: 2.5rem;
  }
  .nav-item:last-child {
    border-right: none;
  }
  .navbar-nav,
  .navbar-light,
  .navbar-link {
    padding: 0;
    color:  $color-black;
    text-decoration: underline;
    &:hover {
      color: $color-black;
      text-decoration: underline;
    }
    a {
      color: $color-black;
      padding: 0 1.5rem 0 1.5rem;
      &:hover {
        color: $color-black;
        text-decoration: underline;
      }
    }
  }
  .cookie-accept-button {
    margin: 1rem 0 4rem 0;
    button {
      background-color: $color-black;;
      color: $color-white;
      font-family: TCCC-UnityHeadline-Bold;
      text-decoration: none;
      line-height: 4rem;
      font-size: 1.8rem;
      text-align: center;
      height: 6rem;
      width: 32rem;
      border: none;
      border-radius: 4rem;
    }
  }
}

@media (max-width: $breakpoint_upto_page) {
  .cookie-notice {
    width: auto;
    height: auto;
    margin: 0 0.5rem;
  }
}
@media (max-width: $breakpoint_mobile_max) {
  .cookie-notice {
    .nav-item {
      display: contents;
    }
  }
}
