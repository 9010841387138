@import "../../scss/variables";
@import "../../scss/fonts";

.profile-page {
  .form-container {
    width: 45rem;
  }
  .profile-button {
    text-align: right;
    input {
      font-family: TCCC-UnityHeadline-Bold;
      font-size: 1.4rem;
      width: 13.4rem;
      border-radius: 2rem;
      background-color: $color-black;
      border: solid 0.1rem $color-black;
      color: $color-white;
      height: 3.4rem;
      line-height: 0rem;
      cursor: pointer;
      font-size: 1.4rem;
      text-align: center;
      &:hover {
        color: $color-white;
      }
    }
  }
  .profile-back-button {
    text-align: left;
    button {
      font-family: TCCC-UnityHeadline-Bold;
      font-size: 1.4rem;
      width: 13.4rem;
      border-radius: 2rem;
      background-color: $color-dark-gray;
      border: solid 0.1rem $color-dark-gray;
      color: $color-black;
      height: 3.4rem;
      line-height: 2rem;
      cursor: pointer;
      font-size: 1.4rem;
      text-align: center;
      &:hover {
        color: $color-black;
      }
    }
  }
  .form-info {
    font-family: TCCC-UnityText-Light;
    font-weight: 300;
    width: 75rem;
    height: 4.9rem;
    text-align: center;
    line-height: 2rem;
    font-size: 1.4rem;
  }
  .form-set2 {
    margin-bottom: 4rem;
  }
  .profile-form {
    margin-bottom: 6.4rem;
  }
}

@media (max-width: $breakpoint_upto_page) {
  .profile-page {
    width: 100%;
  }
}

@media (max-width: $breakpoint_upto_tablet) {
  .profile-page {
    padding: 0 1rem;
    .form-container {
      width: 100%;
      margin-top: 4rem;
    }
    .form-info {
      width: 100%;
      margin: 2rem 0;
    }
  }
}
