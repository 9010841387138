@import "../../scss/variables";
@import "../../scss/fonts";

.fp-page {
  width: $pageSize;
  justify-content: center;
  margin: 0 auto;
  max-width: $pageSize;

  .fp-form {
    margin-top: 6.2rem;
    width: 45rem;
  }
  .submit-button {
    input {
      font-family: TCCC-UnityHeadline-Bold;
      font-size: 1.4rem;
      width: 17rem;
      border-radius: 2rem;
      background-color: $color-black;
      border: solid 0.1rem $color-black;
      color: $color-white;
      margin-top: 4rem;
      height: 3.4rem;
      line-height: 0rem;
      cursor: pointer;
      &:hover {
        color: $color-white;
      }
    }
  }
  .bottom-login-link {
    font-family: TCCC-UnityHeadline-Light;
    font-size: 1.4rem;
    line-height: 2.6rem;
    width: 75rem;
    height: 1.7rem;
    text-align: center;
    margin: 4rem 0 6.4rem 0;
    button {
      text-decoration: underline;
      color: $color-black;
      cursor: pointer;
      border: none;
      background-color: $color-white;
      font-weight: bold;
    }
  }
}
@media (max-width: $breakpoint_upto_page) {
  .fp-page {
    width: auto;
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .fp-page {
    padding: 0 1rem;
    .fp-form {
      margin-top: 4rem;
      width: auto;
    }
    .bottom-login-link {
      width: auto;
    }
    .bottom-login-link {
      margin: 4rem 0 0 0;
    }
  }
}
