@import "../../scss/variables";
@import "../../scss/fonts";

.login-page {
  width: $pageSize;
  margin: 0 auto;
  .login-form {
    margin-top: 6.2rem;
    width: 45rem;
  }
  .login-fp span {
    font-family: TCCC-UnityText-Bold;
    font-size: 1.2rem;
    line-height: 2.6rem;
    margin-top: 1.4rem;
    height: 1.4rem;
    font-weight: bold;
    text-decoration: underline;
    color: $color-black;
    cursor: pointer;
    border: none;
    background-color: $color-white;
    &:hover {
      color: $color-black;
    }
  }
  .login-button {
    input {
      font-family: TCCC-UnityHeadline-Bold;
      font-size: 1.4rem;
      width: 17rem;
      border-radius: 2rem;
      background-color: $color-black;
      border: solid 0.1rem $color-black;
      color: $color-white;
      margin-top: 4rem;
      height: 3.4rem;
      line-height: 0;
      cursor: pointer;
      &:hover {
        color: $color-white;
      }
    }
  }
  .login-register-button {
    button {
      font-family: TCCC-UnityHeadline-Bold;
      font-size: 1.4rem;
      width: 17rem;
      border-radius: 2rem;
      background-color: $color-dark-gray1;
      border: solid 0.1rem $color-dark-gray1;
      color: $color-black;
      margin: 5.3rem 0 6.2rem 0;
      height: 3.4rem;
      line-height: 2.6rem;
      cursor: pointer;
      &:hover {
        color: $color-black;
      }
    }
  }
}

@media (max-width: $breakpoint_upto_page) {
  .login-page {
    width: 100%;
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .login-page {
    width: 100%;
    padding: 0 1rem;
    .login-form {
      width: 100%;
      margin-top: 4rem;
    }
  }
}
