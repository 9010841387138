@import "../../../scss/variables";

header {
  height: 10.4rem;
  .header-top {
    background: transparent
      linear-gradient(90deg, #f30000 0%, #f79900 50%, #6ac9ce 100%) 0% 0%
      no-repeat padding-box;
    height: 1rem;
  }
  .header-logo {
    width: $pageSize;
    height: 9.4rem;
    line-height: 9.4rem;
    margin: 0 auto;
    img {
      width: 23.9rem;
      height: 4.3rem;
      cursor: pointer;
    }
  }
  .header-border {
    border-bottom: 0.2rem solid $color-medium-gray;
    height: 0.2rem;
  }
}

@media (max-width: $breakpoint_upto_page) {
  header {
    .header-logo {
      width: 100%;
    }
  }
}

@media (max-width: $breakpoint_mobile_max) {
  header {
    .header-logo {
      img {
        width: 50%;
        height: auto;
        margin-left: 0.5rem;
      }
    }
  }
}
