.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255,255,255,0.75);
    width: 100%;
    height: 100%;
    z-index: 100;
    & .spinner-wrapper {
        // width: 10%;
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        justify-content: space-between;
        transform: translate(-50%,-50%);
        z-index: 101;
        & .spinner-grow {
            background-color: #E1000F;
            width: 3rem;
            height: 3rem;
        }
    }
}

