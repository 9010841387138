@import "../../scss/variables";
@import "../../scss/fonts";

.ssn-page {
  color: $color-black;
  background-color: $color-white;
  width: 58.1rem;
  height: 45.7rem;
  .color-dark-gray {
    color: $color-dark-gray;
  }
  .color-black {
    color: $color-black;
  }
  .close-button-container {
    text-align: right;
    margin: 10px 10px 0 0;
    button {
      width: 1.2rem;
      height: 1.2rem;
      border: none;
      background-color: $color-white;
      font-size: 1.4rem;
      font-weight: bold;
      text-align: right;
    }
  }
  .title-container {
    width: 56.9rem;
    margin-bottom: 2.9rem;
    .headline {
      margin-top: 1rem;
      width: 56.9rem;
      height: auto;
      font-family: TCCC-UnityHeadline-Bold;
      font-size: 2.8rem;
      line-height: 3.4rem;
      margin-bottom: 2rem;
    }
    .description {
      width: 42.7rem;
      height: auto;
      font-family: TCCC-UnityText-Regular;
      font-size: 1.4rem;
      line-height: 2rem;
      margin: 0 auto;
    }
  }
  .ssn-form {
    width: 100%;
    margin: 0 auto;
    label {
      font-family: TCCC-UnityText-Medium;
      font-size: 1.4rem;
      line-height: 2.6rem;
    }
    .ssn-button {
      input {
        font-family: TCCC-UnityHeadline-Bold;
        font-size: 1.4rem;
        width: 17rem;
        border-radius: 2rem;
        background-color: $color-black;
        border: solid 0.1rem $color-black;
        color: $color-white;
        margin: 4rem 0 4.4rem 0;
        height: 3.4rem;
        line-height: 0;
        cursor: pointer;
        &:hover {
          color: $color-white;
        }
      }
    }
    .ssn1 {
      border: 0.1rem solid $color-dark-gray;
      height: 3.8rem;
      font-size: 2.2rem;
      border-radius: 0.25rem;
      font-family: TCCC-UnityText-Bold;
      margin-bottom: 1rem;
      cursor: text;
      #ssn11,
      #ssn12,
      #ssn13 {
        border: none;
        height: 3rem;
      }
      #ssn11 {
        margin-left: 0.4rem;
        width: 4rem;
      }
      #ssn12 {
        width: 2.8rem;
      }
      #ssn13 {
        width: 6.5rem;
      }
      #ssn11h,
      #ssn12h,
      #ssn11,
      #ssn12,
      #ssn13 {
        color: $color-dark-gray;
      }
      #ssn11h,
      #ssn12h {
        font-family: TCCC-UnityText-Light;
      }
    }
    .ssn2 {
      border: 0.1rem solid $color-dark-gray;
      height: 3.8rem;
      font-size: 2.2rem;
      border-radius: 0.25rem;
      font-family: TCCC-UnityText-Bold;
      cursor: text;
      #ssn21,
      #ssn22,
      #ssn23 {
        border: none;
        height: 3rem;
      }
      #ssn21 {
        margin-left: 0.4rem;
        width: 4rem;
      }
      #ssn22 {
        width: 2.8rem;
      }
      #ssn23 {
        width: 6.5rem;
      }
      #ssn21h,
      #ssn22h,
      #ssn21,
      #ssn22,
      #ssn23 {
        color: $color-dark-gray;
      }
      #ssn21h,
      #ssn22h {
        font-family: TCCC-UnityText-Light;
      }
    }

    .ssn-consent {
      padding-bottom: 10px;

      label {
        font-family: 'TCCC-UnityText-Regular';
        font-family: TCCC-UnityText-Regular;
        font-size: 1.4rem !important;
        display: inline-block;
        line-height: 2rem;
      }

      input[type=checkbox] {
        vertical-align: middle;
      }

      .consent-text {
        padding-left: 5px;
      }
    }
  }
  .alert {
    margin-top: 1rem;
    font-size: 1.4rem;
    font-family: TCCC-UnityText-Bold;
    line-height: 2.6rem;
  }
}

@supports (-webkit-touch-callout: none) {
  .ssn-page {
    .ssn-form {
      .ssn1 {
        #ssn11 {
          width: 6rem;
        }
        #ssn12 {
          width: 5rem;
        }
        #ssn13 {
          width: 8rem;
        }
      }
      .ssn2 {
        #ssn21 {
          width: 6rem;
        }
        #ssn22 {
          width: 5rem;
        }
        #ssn23 {
          width: 8rem;
        }
      }
    }
  }
}

@media (max-width: $breakpoint_upto_page) {
  .ssn-page {
    width: 100%;
    height: auto;
    margin: 0 0.5rem;
    .title-container {
      width: 100%;
      .headline {
        width: 100%;
        height: auto;
      }
      .description {
        width: 100%;
        height: auto;
      }
    }
    .ssn-form {
      width: 100%;
    }
  }
}
