@import "../../scss/variables";
@import "../../scss/fonts";

.home-page {
  .banner {
    width: $layoutSize;
    margin: 0 auto;
    img {
      width: $layoutSize;
      height: auto;
    }
  }
  .headline,
  .description {
    width: $pageSize;
  }
  .reward-details {
    background-color: $color-light-gray;
    margin-top: 2.7rem;
    height: auto;
    .reward-details-page {
      width: $pageSize;
      margin: 0 auto;
      .reward-title-container {
        margin-top: 4rem;
        .reward-title {
          font-family: TCCC-UnityHeadline-Bold;
          font-size: 3.6rem;
          line-height: 4.3rem;
          text-align: center;
          margin: auto;
          width: 49.1rem;
          height: 7.2rem;
        }
      }
      .time-card-container {
        margin-top: 2.5rem;
        .time-block {
          height: auto;
          border-right: $color-dark-gray solid 0.1rem;
          .time-img {
            text-align: right;
            width: 10rem;
            height: auto;
            margin-left: 8rem;
          }
          .time-text {
            width: 27.3rem;
            height: auto;
            line-height: 2.2rem;
            padding: 0 0 2rem 1rem;
            .time-text-title {
              font-family: TCCC-UnityText-Bold;
              font-size: 1.6rem;
              line-height: 2.2rem;
            }
            .time-text-des {
              font-family: TCCC-UnityText-Regular;
              font-size: 1.4rem;
              line-height: 2.2rem;
            }
          }
        }
        .card-block {
          height: 14.5rem;
          padding-left: 5rem;
          .card-img {
            text-align: right;
            width: 14rem;
            height: auto;
          }
          .card-text {
            width: 28rem;
            height: auto;
            padding: 0 1rem 2rem 1rem;
            .card-text-title {
              font-family: TCCC-UnityText-Bold;
              font-size: 1.6rem;
              line-height: 2.2rem;
            }
            .card-text-des {
              font-family: TCCC-UnityText-Regular;
              font-size: 1.4rem;
              line-height: 2.2rem;
            }
          }
        }
      }
      .block3 {
        font-family: TCCC-UnityText-Medium;
        font-size: 1.4rem;
        line-height: 2.6rem;
        width: 100%;
        height: auto;
        margin: 2rem 0 4rem 0;
      }
    }
  }
  .reward-grid-container {
    margin: 6rem 0 6.2rem 0;
    .reward-grid {
      width: $pageSize;
      margin: 0 auto;
      .rdt_Table {
        min-width: 72rem;
        overflow: scroll;
      }
      .rdt_TableHeadRow {
        background-color: $color-black;
        color: $color-white;
        font-family: TCCC-UnityText-Bold;
        font-size: 1.4rem;
        line-height: 1.6rem;
        text-transform: uppercase;
        height: 4rem;
      }
      .rdt_TableRow {
        font-family: TCCC-UnityText-Regular;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: $color-black;
        text-align: justify;
      }
    }
  }
  .not-available {
    font-size: 1.4rem;
    line-height: 2.6rem;
    font-family: TCCC-UnityText-Bold;
    text-align: center;
  }
  .default-button {
    font-family: TCCC-UnityHeadline-Regular;
    font-size: 1.2rem;
    color: $color-white;
    background-color: $color-black;
    width: 12.3rem;
    height: 2.5rem;
    border: none;
    border-radius: 2rem;
  }
  alert {
    width: $layoutSize;
    margin: 0 auto;
  }
}

@media (max-width: $breakpoint_upto_page) {
  .home-page {
    width: 100%;
    .banner {
      width: 100%;
    }
    .reward-details {
      .reward-details-page {
        width: 100%;
        .time-card-container {
          .time-block {
            margin-left: -3rem;
            border: none;
            .time-img {
              margin-left: 13rem;
            }
            .time-text {
              width: 50rem;
            }
          }
          .card-block {
            margin-left: 2rem;
            .card-text {
              width: 50rem;
            }
          }
        }
      }
    }
    .reward-grid-container {
      .reward-grid {
        width: 100%;
      }
    }
    .block3 {
      text-align: center;
    }
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .home-page {
    .banner {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }

    .title-container {
      padding: 0 1rem;
      .headline,
      .description {
        width: 100%;
      }
    }

    .reward-details {
      height: auto;
      padding: 0 1rem;
      .reward-details-page {
        width: 100%;
        height: auto;
        .reward-title-container {
          .reward-title {
            width: 100%;
            height: auto;
            font-size: 2.8rem;
            line-height: 4rem;
          }
        }

        .time-card-container {
          .time-block {
            height: auto;
            border: none;
            margin: 0;
            .time-img {
              margin: auto;
              text-align: center;
              width: auto;
              height: auto;
              padding: 2rem 0 2rem 0;
              img {
                width: 13rem;
                height: auto;
              }
            }
            .time-text {
              width: 100%;
              text-align: center;
              padding: 0 0 2rem 0;
              .time-text-des {
                margin: 0 1rem;
              }
            }
          }
          .card-block {
            padding: 0;
            height: auto;
            margin: auto;

            .card-img {
              text-align: center;
              width: auto;
              height: auto;
              padding: 2rem;
              margin: auto;
              img {
                width: 17rem;
                height: auto;
              }
            }
            .card-text {
              width: 100%;
              text-align: center;
              padding: 0 0 2rem 0;
              .card-text-des {
                margin: 0 1rem;
              }
            }
          }
        }
        .block3 {
          text-align: center;
          margin: 2rem 0.5rem 4rem 0.5rem;
        }
      }
    }
    .reward-grid-container {
      padding: 0 1rem;
      .reward-grid {
        width: 100%;
        overflow: scroll;
      }
    }
  }
}
