@import "../../../scss/fonts";
@import "../../../scss/variables";

.text-number-box {
  label {
    font-size: 1.4rem;
    line-height: 2.6rem;
    font-family: TCCC-UnityText-Medium;
  }
  input {
    height: 3.5rem;
    font-size: 1.4rem;
    border: 1px solid $color-dark-gray;
  }
  p {
    font-family: TCCC-UnityText-Bold;
    font-size: 1.4rem;
    height: 1.4rem;
    display: inline-block;
  }
  .form-control:disabled {
    font-size: 1.4rem;
    line-height: 2.6rem;
    font-family: TCCC-UnityText-Medium;
    color: #8e8e8e;
    background: $color-light-gray 0% 0% no-repeat padding-box;
    border: 1px solid $color-dark-gray;
  }
}
