@import "../../scss/variables.scss";

.register2-page {
  .form-container {
    width: 45rem;
  }
  .register2-button {
    text-align: right;
    input {
      font-family: TCCC-UnityText-Bold;
      width: 13.4rem;
      border-radius: 2rem;
      background-color: $color-black;
      border: solid 0.1rem $color-black;
      color: $color-white;
      height: 3.4rem;
      line-height: 0rem;
      cursor: pointer;
      font-size: 1.4rem;
      text-align: center;
      &:hover {
        color: $color-white;
      }
    }
  }
  .register2-back-button {
    text-align: left;
    button {
      font-family: TCCC-UnityText-Bold;
      width: 13.4rem;
      border-radius: 2rem;
      background-color: $color-dark-gray;
      border: solid 0.1rem $color-dark-gray;
      color: $color-black;
      height: 3.4rem;
      line-height: 2rem;
      cursor: pointer;
      font-size: 1.4rem;
      text-align: center;
      &:hover {
        color: $color-black;
      }
    }
  }
  .bottom-login-link {
    font-family: TCCC-UnityText-Regular;
    font-size: 1.4rem;
    line-height: 2.6rem;
    width: 75rem;
    height: 1.7rem;
    text-align: center;
    margin: 4rem 0 6.4rem 0;
    button {
      text-decoration: underline;
      color: $color-black;
      cursor: pointer;
      border: none;
      background-color: $color-white;
      font-weight: bold;
    }
    span {
      font-family: TCCC-UnityText-Bold;
    }
  }
  .form-info {
    font-family: TCCC-UnityText-Light;
    font-weight: 300;
    width: 75rem;
    height: 4.9rem;
    text-align: center;
    line-height: 2rem;
    font-size: 1.4rem;
  }
  .form-set2 {
    margin-bottom: 4rem;
    margin-bottom: 6.4rem;
  }
}

@media (max-width: $breakpoint_upto_page) {
  .register2-page {
    .bottom-login-link {
      width: auto;
    }
    .form-info {
      width: auto;
      margin-bottom: 3rem;
    }
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .register2-page {
    padding: 0 1rem;
    .form-container {
      width: auto;
    }
  }
}
