@import "../../../scss/fonts";
@import "../../../scss/variables";

.dropdown-input {
  label {
    font-size: 1.4rem;
    line-height: 2.6rem;
    font-family: TCCC-UnityText-Medium;
  }
  .empty-option {
    color: $color-dark-gray;
  }
  select {
    height: 3.5rem;
    font-size: 1.4rem;
    border: 1px solid $color-dark-gray;
  }
  p {
    font-family: TCCC-UnityText-Bold;
    font-size: 1.4rem;
    height: 1.4rem;
    display: inline-block;
  }
}
