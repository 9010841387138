@import "../../../scss/fonts";
@import "../../../scss/variables";

.title-container {
  width: $pageSize;
  margin: 0 auto;
  .headline {
    font-family: TCCC-UnityHeadline-Bold;
    font-size: 4.8rem;
    line-height: 5.8rem;
    margin-top: 6.2rem;
    height: auto;
    width: $pageSize;
    letter-spacing: 0;
    color: $color-black;
    font-weight: bold;
    text-align: center;
  }
  .description {
    font-family: TCCC-UnityText-Light;
    font-size: 2rem;
    line-height: 2.8rem;
    margin: 1.4rem 0 2.7rem 0;
    width: $pageSize;
    height: auto;
    color: $color-black;
    text-align: center;
  }
}
@media (max-width: $breakpoint_upto_page) {
  .title-container {
    width:100%;
    margin:0;
    .headline {
      margin-top: 4rem;
      width: 100%;
      height: auto;
    }
    .description {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: $breakpoint_upto_tablet) {
  .title-container {
    .headline {
      font-size: 3rem;
      line-height: 4.5rem;
      margin-top: 3rem;
    }
  }
}
@media (max-width: $breakpoint_mobile_max) {
  .title-container {
    .headline {
      font-size: 2rem;
      line-height: 3.2rem;
      margin-top: 2rem;
    }
  }
}
